<template>
  <div class="content-box">
    <img class="down-bg" src="@/assets/registeredAgent/down.png" alt="" />
    <div class="btn-box">
      <div class="logo">
        <!-- <img v-if="appLogoUrl" :src="appLogoUrl" alt="" /> -->
        <img src="@/assets/registeredAgent/down-icon.png" alt="" />
      </div>
      <div class="name">联动pay展业版</div>
      <div class="submit-btn" @click="downApp">立即下载APP</div>
    </div>
  </div>
</template>

<script>
import { shareConfig } from "@/utils/share.js";
import { getAuthInfo } from "@/api/common";
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      appLogoUrl: "",
      appDownloadUrl: "",
      appName: "",
      title: "<联动pay展业版>下载",
      desc: "联动pay展业助手、功能强大、操作便捷、数据清晰，立即下载App开始使用吧！",
      imgUrl: "https://oss.umfpay.com/image/ac/6f/e0/44/80b978e4bf1d4ad39f9b4b30b254e4b5.png", //展业logo
    };
  },
  mounted() {
    shareConfig(this.title, this.desc, this.imgUrl);
  },
  methods: {
    weiConfig() {
      if (this.$isWeiXin()) {
        // return false;
        getAuthInfo({ urlType: 1 }).then((res) => {
          // console.log("签名res", res);
          console.log("签名res", res);
          wx.config({
            debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.appId, // 必填，公众号的唯一标识
            // nonceStr: 'bPgBUvFsAhLCMVi7', // 必填，生成签名的随机串
            timestamp: res.timestamp, // 必填，签名
            nonceStr: res.nonceStr, // 必填，签名
            signature: res.signature, // 必填，签名
            // signature: 'a949ddd47361a56d0a0cbe78f3ef56267cadd66c', // 必填，签名
            jsApiList: ["onMenuShareTimeline", "onMenuShareAppMessage"], // 必填，需要使用的JS接口列表
          });
          let shareData = {
            title: "<联动pay展业版>下载",
            desc: "联动pay展业助手、功能强大、操作便捷、数据清晰，立即下载App开始使用吧！",
            imgUrl: "https://oss.umfpay.com/image/ac/6f/e0/44/80b978e4bf1d4ad39f9b4b30b254e4b5.png",
          };

          wx.ready((res) => {
            wx.onMenuShareTimeline(shareData);
            wx.onMenuShareAppMessage(shareData);
          });
        });
      } else {
      }
    },
    /* 判断用户手机为安卓还是iphone */
    checkPhone() {
      // console.log('navigator', navigator)
      let self = this;
      let agent = navigator.userAgent || navigator.vendor || window.opera;
      if (agent != null) {
        let agentName = agent.toLowerCase();
        if (/android/i.test(agentName)) {
          self.isAndroid = true;
          console.log("安卓机", self.isAndroid);
          window.location.href = `http://umfpay.zfmgr.top/api/resource/update/packages/package?appType=1`;
        } else if (/iphone/i.test(agentName)) {
          self.isIOS = true;
          console.log("苹果机", self.isIOS);
          window.location.href = `https://apps.apple.com/cn/app/ld办公助手/id1622544156`;
        }
      }
    },
    //点击下载应用
    downApp() {
      this.checkPhone();
    },
  },
  beforeCreate() {
    this.$nextTick(() => {
      document.body.setAttribute("style", "background-color:#fff");
    });
  },
  //实例销毁之前钩子--移除body 标签的属性style
  beforeDestroy() {
    document.body.removeAttribute("style");
  },
};
</script>
<style lang="less" scoped>
.content-box {
  position: relative;
  .down-bg {
    width: 100%;
    height: 100%;
  }
  .btn-box {
    position: absolute;
    top: 822px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .logo {
      width: 230px;
      height: 230px;
      margin-bottom: 18px;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .name {
      margin-bottom: 53px;
      font-size: 36px;
      height: 66px;
      line-height: 66px;
      color: #333333;
      font-weight: bold;
    }
    .submit-btn {
      width: 560px;
      height: 94px;
      line-height: 94px;
      text-align: center;
      background-color: #ff4741;
      border-radius: 47px;
      font-size: 46px;
      font-weight: bold;
      color: #ffffff;
    }
  }
}
</style>
